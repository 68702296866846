<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form">
            <a-form-item label="区域">
              <a-select
                class="form-control"
                :options="sybList"
                v-decorator="['syb']"
                placeholder="请选择"
                allowClear
              ></a-select>
            </a-form-item>
            <a-form-item label="项目">
              <a-select-pro
                class="form-control"
                v-decorator="['projectCode']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="projectList"
                :dropdownMatchSelectWidth="false"
              ></a-select-pro>
            </a-form-item>
            <a-form-item label="通知类型">
              <a-select
                class="form-control"
                v-decorator="['type']"
                placeholder="请选择"
                allowClear
                :options="typesMap.type"
              ></a-select>
            </a-form-item>
            <a-form-item label="时间">
              <a-range-picker v-decorator="['time', { initialValue: [moment()] }]" />
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-button icon="plus-circle" type="primary" @click="addRow">添加</a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form class="flex-form form-label-5 a-form-mb-2" :form="modal.form">
        <a-form-item label="项目">
          <a-select-pro
            class="form-control"
            v-decorator="['projectCode', { rules: [{ required: true, message: '请选择' }] }]"
            placeholder="请选择"
            showSearch
            :options="projectList"
            :dropdownMatchSelectWidth="false"
          ></a-select-pro>
        </a-form-item>
        <a-form-item label="通知类型">
          <a-select
            class="form-control"
            v-decorator="['type', { rules: [{ required: true, message: '请选择' }] }]"
            placeholder="请选择"
            :options="typesMap.type"
          ></a-select>
        </a-form-item>
        <a-form-item label="时间">
          <a-range-picker
            v-decorator="['time', { rules: [{ required: true, message: '请选择' }] }]"
            :disabledDate="disabledDate"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import ASelectPro from '@/components/SelectPro.vue'
import { getProjectList, getSybList } from '@/service/getData/aie'
import { getTypeName } from '@/utils'
import moment from 'moment'

export default {
  components: {
    ASelectPro,
  },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '区域',
        width: '8%',
        align: 'center',
        dataIndex: 'sybName',
      },
      {
        title: '项目名称',
        width: '15%',
        align: 'center',
        dataIndex: 'projectName',
      },
      {
        title: '通知类型',
        width: '8%',
        align: 'center',
        dataIndex: 'type',
        customRender: (text) => {
          return <span>{getTypeName(text, this.typesMap.type)}</span>
        },
      },
      {
        title: '时间',
        width: '10%',
        align: 'center',
        dataIndex: 'noNotifyTime',
      },
      {
        title: '状态',
        align: 'center',
        width: '5%',
        dataIndex: 'status',
        customRender: (text) => {
          return (
            <span>
              {text === 1 && <span class="text-gray-500">已停用通知</span>}
              {text === 0 && <span>正常发送通知</span>}
            </span>
          )
        },
      },
      {
        title: '操作',
        align: 'center',
        width: '5%',
        customRender: (text, record) => {
          return (
            <div>
              {record.status === 1 && (
                <a
                  onClick={() => {
                    this.changeRowStatus(record, 0)
                  }}
                >
                  恢复通知
                </a>
              )}
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],
      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },
      projectList: [],
      sybList: [],
    }
  },
  computed: {
    typesMap() {
      return {
        type: [
          {
            label: '过车风险通知',
            value: 1,
          },
          {
            label: '设备离线通知',
            value: 2,
          },
        ],
      }
    },
  },
  methods: {
    moment,
    getTypeName,
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().startOf('day')
    },

    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const params = this.formatTimeRangeParams(this.getPageParams(_pageNo, _pageSize), 'time', {
          format: 'YYYY-MM-DD',
        })
        const res = await this.$axios.post('/aie/web/no/notify/config/page', {
          ...params,
          status: 1,
        })
        const list = res.data.rows
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async loadSybList() {
      this.sybList = await getSybList()
    },
    async loadProjectList() {
      this.projectList = await getProjectList()
    },
    async addRow() {
      this.resetModal()

      this.modal.title = '新增停用通知记录'
      this.modal.visible = true
    },
    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          try {
            const id = this.modal.data.id
            const params = this.formatTimeRangeParams(values, 'time', {
              format: 'YYYY-MM-DD',
            })
            console.log(params)
            if (id) {
            } else {
              await this.$axios.post('/aie/web/no/notify/config/insert', {
                id,
                ...params,
              })
            }

            this.$message.success('操作成功!')
            this.modal.visible = false
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    async changeRowStatus(row, status) {
      await this.$axios.post('/aie/web/no/notify/config/update/status', {
        id: row.id,
        status,
      })
      this.doQuery()
    },
  },
  async mounted() {
    this.loadProjectList()
    this.loadSybList()
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
